class ActivityResult
{

  /** @type{boolean} */
  is_correct
  /** @type{boolean} */
  joker_earned
  /** @type{boolean} */
  joker_used
  /** @type{number} */
  normalized_score

  /** @type{object} */
  additionalInfos

  fromJson(json) {
    Object.assign(this, json)
  }


}

export default ActivityResult