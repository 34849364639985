import { useEffect } from 'react';

import Button from '@/components/shared/Button/Button';
import Popup from '@/components/shared/Popup/Popup';
import AppState, { POPUPS } from '@/js/AppState';
import Minigames from '@/js/Minigames/Minigames';
import SequenceScenario from '@/js/SequenceScenario';
import clsx from 'clsx';
import { MdOutlineReplay } from 'react-icons/md';
import PopupsManager from 'react-sdk/PopupsManager';
import SoundPlayer from 'react-sdk/SoundPlayer';
import Texts from 'react-sdk/Texts';
import classes from './PopupReplayMinigame.module.css';

import FTUE, { FTUE_state } from '@/js/FTUE';
import MinigameFtues from '@/js/Minigames/MinigameFtues';
import Analytics from 'react-sdk/Vertx/Analytics';
const ReplayMG = ({mg}) => {



  return (
    <div className={clsx(classes.mg)}>
      <span className={classes.mg_icon}><img src={mg.img_url} /></span>
      <span className={classes.mg_title}>
        <div>{mg.title}</div>
        <span className={classes.mg_stars}>
          {mg.currentStars} / {mg.totalStars}
        </span>
      </span>
      <span className={classes.mg_replaybtn}>
        <Button icon={<MdOutlineReplay />} variant='small' onClick={() => Minigames.play(mg.id, null, true)} />
      </span>

    </div>
  )

}



const PopupReplayMinigame = () => {

  function handleClose() {
    if(AppState.test_mg_mode) return

    PopupsManager.close(POPUPS.REPLAY_MG)
  }

  useEffect(() => {
    SoundPlayer.playSound("music_batUI")

    return () => {
      SoundPlayer.playSound("music_map")
    }
  }, []);

  let mgs = Minigames.unlocked
  if(AppState.debugMode || AppState.test_mg_mode) mgs = Minigames.all

  return (
    <Popup className={classes.PopupReplayMinigame} onClose={!AppState.test_mg_mode && handleClose}>
      <div className="popup-title">
        <h3>{Texts.get("popup-replay-mg-title")}</h3>
      </div>

      <div className={classes.list}>
        {mgs.map(mg => <ReplayMG mg={mg} key={mg.id} />)}

      </div>

      {AppState.debugMode &&
      <div><button onClick={() => MinigameFtues.viewed_types = []}>Clear ftues.viewed_types</button></div>}


    </Popup>
  );
};

export default PopupReplayMinigame;
