import { makeObservable, observable, computed } from "mobx"
import Debrief from "./Debrief";

import ActivityResult from './ActivityResult'

export const STATES = {
  CORRECT: "CORRECT",
  WRONG: "WRONG",
  MISSED: "MISSED",
}

class ActivityPedaInstance
{
  /** @type {ActivityResult} */
  result = null

  /** @type {Array<Debrief>} */
  debriefs = []


  jokerUsed = false

  addDebrief(json, isCorrect) {
    this.debriefs.push(new Debrief(json, isCorrect))
  }

  /**
   *
   * @param {ActivityResult} result
   */
  setResult(result) {
    if(!result instanceof ActivityResult) {
      result = new ActivityResult().fromJson(result)
    }
    this.result = result
  }

  /** @returns {Array} */
  getAnswer()  { console.log("getAnswer: OVERRIDE ME !"); }
  useJoker()   { console.log("useJoker: OVERRIDE ME !"); }


  // NOTE override ceci pour retourner un truc custom (null si on ne veut pas l'afficher, ou gérer l'affichage ailleurs dans le component Game par exemple)
  getDebrief() {
    // ici on renvoie le debrief qu'il faut
    if(this.debriefs.length === 0) return null

    if(this.debriefs.length === 1) return this.debriefs[0]

    if(this.debriefs.length === 2) {
      if(this.result.is_correct) {
        return this.debriefs.find(d => d.isCorrect)
      }
      else {
        return this.debriefs.find(d => !d.isCorrect)
      }
    }

    return null

  }

  get jokerAvailable() {
    // NOTE renvoyer true si la question peut permettre l'utilisation d'un joker. Par exemple, ce n'est pas possible pour un qcm avec 2 choix, cela donnerait automatiquement la bonne réponse.
    // A override dans les classes enfant
    console.log("jokerAvailable : OVERRIDE ME !!!")
    return false
  }


  observe() {
    makeObservable(this, {
      result: observable,
      jokerUsed: observable,

      jokerAvailable: computed
    })
  }
}


export default ActivityPedaInstance