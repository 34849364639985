import {
  succubus_data_DataManager,
  succubus_data_DataConfig,
  succubus_data_utils_DataColumns} from "./datamanagertypes.d.ts";

const data = window?.succubus?.data
const dm = data?.DataManager

export const available = () => dm !== undefined

if(available()) {
  dm.init()
}

/** @type {succubus_data_DataManager} */
export default dm?.instance

/** @type {succubus_data_utils_DataColumns} */
export const DataColumns = data?.utils?.DataColumns

/** @type {succubus_data_DataConfig} */
export const DataConfig = data?.DataConfig